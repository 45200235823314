import Hls from 'hls.js'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

interface Props extends React.HTMLProps<HTMLVideoElement> {
  manifest: string
  mp4: string
}

const HLSPlayer = forwardRef<HTMLVideoElement, Props>(
  ({ manifest, mp4, ...props }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    useImperativeHandle(ref, () => videoRef.current!) // Expose internal ref to forwardedRef. (Allows for callback & regular useRef)

    useEffect(() => {
      const src = manifest
      const { current: video } = videoRef
      if (!video) return

      let hls: Hls | null = null

      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // Safari
        video.src = src
      } else if (Hls.isSupported()) {
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)
      } else {
        video.src = mp4
      }

      return () => {
        hls?.destroy()
      }
    }, [manifest, mp4])

    return <video {...props} ref={videoRef} />
  },
)

HLSPlayer.displayName = 'HLSPlayer'

export default HLSPlayer
